.authButton {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: 3px solid #0e69c2;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 25px;
  min-width: 100%;
  min-height: 51px;
}

.authButtonConsult {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: 3px solid #0e69c2;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 200px !important;
  min-height: 51px;
}

.loginButton {
  background: #f05b5d;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  height: 50px;
}

.btn_outline {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 159.6%;
  align-items: center;
  text-align: center;
  color: #0e69c2;
  border: 1px solid #0e69c2;
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  width: 100%;
  height: 52px;
  background: transparent;
}

.btn-bg {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: 3px solid #0e69c2;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 224px;
  min-height: 51px;
}

.dashBoardTransparent {
  border: 1.5px solid #4284f3;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #4284f3;
  background: transparent;
  min-width: 150px;
  min-height: 35px;
}
.genderTransparent {
  // border: 1.5px solid #D0D5DD;
  border: 1px solid #a89f9f;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #000;
  background: transparent;
  min-width: 90px;
  min-height: 35px;
}

.dashboardPrimaryButton {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: 3px solid #0e69c2;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 150px;
  min-height: 33px;
}

.dashBoardTransparentBtn {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #0e69c2;
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  background: transparent;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  width: 200px;
  min-height: 51px;
}

.startButton {
  border: 1px solid #4284f3;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #4284f3;
  background: transparent;
  min-width: 200px;
  height: 40px;
}

.bookConsultBtn {
  background: #4284f3;
  border: 1px solid #4284f3;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  height: 40px;
  min-width: 200px;
}

.registerLoginBtn {
  background: #4284f3;
  border: 1px solid #4284f3;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  height: 38px;
  min-width: 150px;
}

.invoicePrimaryBtn {
  background: #0e69c2;
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  min-width: 250px;
  height: 50px;
}

.invoiceTransparentBtn {
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #0e69c2;
  min-width: 250px;
  height: 50px;
  background: transparent;
}

.searchButton {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: 3px solid #0e69c2;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 100%;
  min-height: 65px;
}

.modalPrimaryBtn {
  background: #0e69c2;
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  min-width: 150px;
  height: 45px;
}

.smallBtn {
  background: #0e69c2 !important;
  border: 1px solid #0e69c2 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-family: Poppins;
  color: #ffffff !important;
}

.coupoButton {
  background: #0e69c2;
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  color: #ffffff;
  height: 38px;
}

.modalSecondaryBtn {
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #0e69c2;
  min-width: 150px;
  height: 45px;
  background: transparent;
}

.disabled {
  background: #4881b9;
  border: 3px solid #4881b9;
  cursor: not-allowed;
}

.consultation-start-btn {
  min-width: 122px !important;
}

.mobileCardButton {
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  min-width: 150px;
  height: 40px;
  background: #0e69c2;
}

.mobileSpecialist {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  color: #0e69c2;
  border: 1px solid #0e69c2;
  box-sizing: border-box;
  background: transparent;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 10px 15px;
}

.mobileConsult {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  color: white;
  border: none;
  box-sizing: border-box;
  background: #0e69c2;
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 4px 37px rgba(0, 0, 0, 0.06));
  border-radius: 8px;
  padding: 10px 15px;
}

.viewAndApplyBtn {
  background: #0e69c2;
  border-radius: 8px;
  width: 160px;
  height: 38px;
  outline: none;
  border: none;
  color: #ffffff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressNewButton {
  background: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  outline: none;
  width: 100%;
  height: 55px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: #0e69c2;
}

.shopNow {
  background-color: #0e69c2;
  border-radius: 5px;
  border: none;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
}

.genderButton {
  /* BG */

  width: 100px;
  height: 45px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  color: black;
  border-radius: 12px;
  background: #f8fafc;
  border: 1px solid #cbd5e1;
}

.genderActive {
  transition: ease-in-out 0.1s;
  color: white;
  background: linear-gradient(97.71deg, #0a5394 10.96%, #5487b4 100.16%, #ffffff 206.44%);
}

.authSubscription1 {
  border: none;
  color: #fff;
  background: #0a5394;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 250px;
  height: 45px;
  cursor: pointer;
}

.authSubscription2 {
  border: none;
  color: #0a5394;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 250px;
  height: 45px;
  letter-spacing: 1px;
}

.simpleCancel {
  width: 120px;
  height: 40px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  padding: 5px;
  color: #000;
  border: 2px solid #d0d5dd;
  cursor: pointer;
  border-radius: 6px;
  background: #fff;
}
.simpleDelete {
  width: 120px;
  height: 40px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  padding: 5px;
  color: #fff;
  border-radius: 6px;
  border: 2px solid red;
  cursor: pointer;
  background: red;
}
.linkButton {
  width: 250px;
  height: 40px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  padding: 0px;
  color: #0a5394;
  cursor: pointer;
  border: none;
  text-decoration: underline;
  background-color: white;
  text-align: left;
}
.sc-buybutton {
  width: 150px;
  height: 40px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  color: #0090ff;
  border-radius: 6px;
  background: #fff;
  border: 2px solid #0090ff;

  &:focus {
    transition: ease-in-out 0.1s;
    border: none;
  }
}

.sc-button {
  background: #0a5394;
  border-radius: 9px;
  width: 120px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.2px;

  /* base/white */
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .shopNow {
    font-size: 12px;
    padding: 10px;
  }

  .genderButton {
    width: 82px;
    height: 44px;
    font-size: 14px;
  }
  .genderTransparent {
    width: 90px;
    height: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .genderButton {
    width: 82px;
    height: 44px;
    font-size: 14px;
  }
  .genderTransparent {
    height: 44px;
  }
}
