.commonLayoutWrapper {
  background-color: #f5f7fb;
  min-height: 100vh;
  object-fit: cover;
}
.mainLayout {
  background: #f5f7fb;
  min-height: 100vh;
}

.main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.diagnostic-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}

.landing-main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
}

.surgery-main-container {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
}

// .body-container {
//   min-height: 30.5vh;
// }
.dashboard-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1680px;
  // min-width: 978px;
}

.body-container {
  min-height: 30.5vh;
}

.lorem-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #6b6b6b;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lorem-content-surgery {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #6b6b6b;
}

//text box number hide
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scroll-x {
  white-space: nowrap;
  overflow-x: auto;
}

.userNameHeader {
  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    display: flex;
    align-items: center;
    color: #104982;
    margin-bottom: 0px;
  }
}

.ant-drawer-body {
  padding: 0px !important;
}

@media only screen and (max-width: 600px) {
  .userNameHeader {
    h3 {
      font-size: 20px;
    }
  }
}