.nav-bar {
  display: none;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  top: 0;
  left: 0;
  width: 250px;
  padding: 15px 0px;
  transition: width 0.3s, left 0.3s;
  z-index: 1102;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
    // width: 7px;
  }
  //   &::-webkit-scrollbar-thumb {
  //     background-color: lightgrey;
  //     border-radius: 10px;
  //   }
  //   &::-webkit-scrollbar-track {
  //     background-color: white;
  //   }
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 1101;
  &:not(.open) {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .nav-bar {
    display: block;
    &:not(.open) {
      left: -250px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .nav-bar {
    width: 230px;
    &:not(.open) {
      left: -230px;
    }
  }
}
