.searchBox {
  width: 100%;

  img {
    position: relative;
    left: -87%;
  }
  .searchInput {
    background: #e9e9e9;
    border-radius: 4px;
    border: none;
    outline: none;
    height: 35px;
    // width: 230px;
    //   padding: 0px 0px 30px 0px;
    padding: 0px 20px 0px 50px;
  }
}

.partnerLogo{
  width: 6rem;
  height: 3rem;
}
.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #353a3d;
}
.notificationBadge {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translate(61%, -46%);
  border-radius: 50%;
  padding: 1px 5px;
  background-color: #eb5757;
}
.headerText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d1d1d;
  padding: 0px 15px;
  cursor: pointer;
  .newName{
    font-size: 9px;
    color: white;
    background-color: #0e69c2;
    border-radius: 50%;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 4px;
    padding-left: 4px;
    box-shadow: 0px 0px 4px 3px whitesmoke;
  }
}
.headerSome {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d1d1d;
  padding: 0px 15px;
  cursor: pointer;
  .newName{
    font-size: 9px;
    color: white;
    background-color: #0e69c2;
    border-radius: 50%;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 4px;
    padding-left: 4px;
    box-shadow: 0px 0px 4px 3px whitesmoke;
  }
}
.dropDown {
  a {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1d1d1d;
    &:hover {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #1d1d1d !important;
    }
  }
}
.header-logo {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    display: none;
    color: black;
    cursor: pointer;
  }
  img {
    width: 170px;
    height: 40px;
  }
}

.blink_offer {
  animation: blinker 1s step-start infinite;
}

.blink_surgery {
  animation: blinker 2s linear infinite;
}

.gradient-border {
  --borderWidth: 2px;
  background: white;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 300% 300%;
}


.carePlusText-profile {
  display: block;
  color: #F7931A;
  font-size: 9px !important;
  font-style: italic;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1140px) {
  .headerText {
    font-size: 15px;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .headerText {
    font-size: 15px;
    padding: 0px 7px;
  }
}

@media only screen and (max-width: 992px) {
  .header-logo {
    svg {
      display: block;
    }
  }
}

@media only screen and (max-width: 600px) {
  .dropDown {
    a {
      padding: 0.5rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .header-logo {
    img {
      width: 110px;
      height: auto;
    }
  }
}
