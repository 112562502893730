@import "../../../assets/scss/variables.scss";

.sideBar {
  background-color: white;
  min-height: 100%;
  width: 250px;
  .activeBar {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #0e69c2;
    text-decoration: none !important;
    :hover {
      text-decoration: none !important;
    }
  }
  .inActiveBar {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $sidebar-gray;
    text-decoration: none !important;
    :hover {
      text-decoration: none !important;
    }
  }
  .active-div {
    background-color: rgba(255, 255, 255, 0.19) !important;
  }
}

@media only screen and (max-width: 600px) {
  .sideBar {
    width: 230px;
    .activeBar {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #0e69c2;
      text-decoration: none !important;
      :hover {
        text-decoration: none !important;
      }
    }
    .inActiveBar {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: $sidebar-gray;
      text-decoration: none !important;
      :hover {
        text-decoration: none !important;
      }
    }
  }
}
