$primary-color: #0e69c2;
$primary-light-blue: #4284f3;
$primary-header: #243d63;
$gray: #9a9a9a;
$gray-2: #4f4f4f;
$sidebar-gray: #656565;
$black: #000000;
$white: #ffffff;
$blog-text: #444444;
$footer-text: #413a3a;
$header-text: #1d1d1d;
$profile-header-gray: #5a5a5a;
$full-height: 100vh;
$light-blue: #ebf2fa;
$mb: 0;

.m-0 {
  margin-bottom: $mb;
}
.sideBarGray {
  color: $sidebar-gray;
}
.w-100 {
  width: 100%;
}
.text-black {
  color: $black;
}
.text-gray {
  color: $gray;
}
.text-brown {
  color: $footer-text;
}
.text-white {
  color: $white;
}
.bg-white {
  background-color: $white;
}

.primary-blue {
  color: $primary-color;
}
.light-bg {
  background-color: $light-blue;
}
.primary-background {
  background-color: $primary-color;
}

.primary-lightBlue {
  color: $primary-light-blue;
}

.primary-header {
  color: $primary-header;
}

.profile-header {
  color: $profile-header-gray;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-disable {
  cursor: not-allowed;
}
.f-lato {
  font-family: Lato;
}
.f-popins {
  font-family: Poppins;
}
.f-roboto {
  font-family: Roboto;
}
.pageBackground {
  background: #f5f7fb;
  padding-top: 60px;
}

.gap-2 {
  gap: 10px;
}

.gap-3 {
  gap: 15px;
}
